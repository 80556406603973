import React from "react";
import PropTypes from "prop-types";

const LandingPageHeroPriceTileHoverImage = ({ initialImage, hoverImage }) => {
  return (
    <>
      <div className="absolute object-cover mx-4 lg:mx-8">{initialImage}</div>
      <div className="relative z-10 mx-4 opacity-0 lg:mx-8 hover:opacity-100">{hoverImage}</div>
    </>
  );
};

LandingPageHeroPriceTileHoverImage.propTypes = {
  initialImage: PropTypes.object.isRequired,
  hoverImage: PropTypes.object.isRequired,
};

export default LandingPageHeroPriceTileHoverImage;
