import React from "react";
import Carousel from "react-card-carousel";

export default (props) => {
  return (
    <Carousel className="" autoplay>
      {props.children}
    </Carousel>
  );
};
