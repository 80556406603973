import React, { useEffect, useContext, useState } from "react";
import * as landingPageProductCategoriesService from "../../services/LandingPageProductCategoriesService";
import productCategoryConfigurationData from "../../data/productCategoryConfigurationData";
import LandingPageProductCategoryButton from "../LandingPageProductCategoryButton";
import LandingPageProductCategoryLink from "../LandingPageProductCategoryLink";
import { window } from "browser-monads";
import WindowSizeContext from "../../contexts/windowSizeContext";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import useNavigationData from "../../hooks/useNavigationData";
import { v4 as uuidv4 } from "uuid";
import { convertUnderscoresToSpaces } from "../../utils/StringUtils";

/**
 * The Landing Page Catagories component renders a section element that contains the
 * product category filtering UI on the Landing Page.
 *
 * @returns {element} A section element containing the layout, text and images for the product Category filtering.
 */
export default () => {
  const navigationData = useNavigationData();
  const windowSize = useContext(WindowSizeContext);

  // const productCategoryData = navigationData.menuData;
  const menuData = navigationData.menuData;
  const productCategories = navigationData.parentMenuData;
  const { productCategoryConfigurations } = productCategoryConfigurationData;

  const initialSelectedProductCategory = landingPageProductCategoriesService.getInitialSearchParamsUsing(window);

  const [selectedProductCategory, setSelectedProductCategory] = React.useState(initialSelectedProductCategory);
  const [filteredProductSubCategories, setFilteredProductSubCategories] = React.useState([]);
  const [selectProductCategoryConfig, setSelectedProductCategoryConfig] = React.useState([]);
  const [isLargeScreenView, setIsLargeScreenView] = useState(windowSize.isLargeScreenView);
  const [productCategoryData] = React.useState(menuData);
  const [productCategoryConfigurationArray] = React.useState(productCategoryConfigurations);

  const filterProductCategoriesUsing = (selectedProductCategory) => {
    setSelectedProductCategory(selectedProductCategory);
  };

  useEffect(() => {
    const results = landingPageProductCategoriesService.getProductSubCategoryDataUsing(selectedProductCategory, productCategoryData);
    const selectedCategoryConfig = landingPageProductCategoriesService.getProductConfigurationDataItemUsing(selectedProductCategory, productCategoryConfigurationArray);

    setFilteredProductSubCategories(results);
    setSelectedProductCategoryConfig(selectedCategoryConfig);

    setIsLargeScreenView(windowSize.isLargeScreenView);
  }, [productCategoryData, productCategoryConfigurationArray, selectedProductCategory, windowSize.isLargeScreenView]);

  return (
    <section className="body-font">
      <div className="container mx-auto">
        <div className="flex flex-wrap flow-hidden">
          <div className="w-full overflow-hidden xl:w-3/12"></div>
          <div className="w-full overflow-hidden text-center xl:w-6/12">
            <h1 className="text-2xl text-center ">Un perfil, un sitio web, TODOS los servicios públicos</h1>
          </div>
          <div className="w-full overflow-hidden xl:w-3/12"></div>
        </div>

        <div className="flex flex-wrap overflow-hidden">
          <div className="w-full overflow-hidden xl:w-3/12"></div>
          <div className="w-full overflow-hidden text-center xl:w-6/12">
            <h2 className="text-xl text-blue-500">Comparamos los precios de 30 servicios, productos y servicios públicos distintos, pero sólo presentamos los 5 mejores para cada servicio </h2>
          </div>
          <div className="w-full overflow-hidden xl:w-3/12"></div>
        </div>

        <div className="flex flex-wrap overflow-hidden">
          <div className="w-full overflow-hidden xl:w-3/12"></div>
          <div className="w-full overflow-hidden text-center xl:w-6/12">
            <h3 className="text-lg lg:mt-2">¿En qué estás interesado el día de hoy?</h3>
          </div>
          <div className="w-full overflow-hidden xl:w-3/12"></div>
        </div>

        <div className="flex mt-10 overflow-hidden lg:flex-wrap">
          <div className="w-full overflow-hidden xl:w-1/12"></div>
          <div className="w-full lg:w-10/12 xl:w-10/12">
            {!isLargeScreenView && (
              <div className="flex items-center justify-center w-full mb-1 text-xs text-center text-gray-500">
                <CaretLeftOutlined className="text-yellow-500" />
                Scroll
                <CaretRightOutlined className="text-yellow-500" />
              </div>
            )}
            <div className="flex overflow-x-scroll lg:overflow-hidden">
              {productCategories.map((productCategory) => {
                const productCategoryConfig = landingPageProductCategoriesService.getProductConfigurationDataItemUsing(productCategory, productCategoryConfigurations);
                return (
                  <LandingPageProductCategoryButton
                    key={uuidv4()}
                    activeProductCategory={selectedProductCategory}
                    filterProductCategoryHandler={filterProductCategoriesUsing}
                    productCategory={productCategory}
                  >
                    {productCategoryConfig.landingPageIcon}
                  </LandingPageProductCategoryButton>
                );
              })}
            </div>
          </div>
          <div className="w-full overflow-hidden xl:w-1/12"></div>
        </div>

        <div className="flex flex-wrap mt-1 overflow-hidden">
          <div className="w-full overflow-hidden xl:w-1/12"></div>
          <div className="w-full pt-4 pb-2 overflow-hidden text-center bg-blue-100 lg:pb-32 lg:pt-20 xl:w-10/12">
            <div className="text-center">
              {selectProductCategoryConfig.landingPageIcon}
              <div className="pt-4 mx-auto text-gray-500 capitalize text-md lg:text-xl">{convertUnderscoresToSpaces(selectedProductCategory)}</div>
              <div className="mx-10 text-sm text-blue-600 lg:mx-auto lg:text-lg ">{selectProductCategoryConfig.subText}</div>
            </div>
            <div className="flex flex-wrap justify-center mx-24 mt-10 mb-20 text-center ">
              {filteredProductSubCategories.map((subCategory) => {
                const premiumSubCatagories = selectProductCategoryConfig.premiumSubCatagories;
                const productSubCategory = subCategory.menuText.toLowerCase();
                const isPremiumSubCategory = premiumSubCatagories.indexOf(productSubCategory) >= 0;
                return <LandingPageProductCategoryLink key={uuidv4()} productSubCategory={subCategory.menuText} productSubCategoryUrl={isPremiumSubCategory ? undefined : subCategory.slug} />;
              })}
            </div>
          </div>
          <div className="w-full overflow-hidden xl:w-1/12"></div>
        </div>
      </div>
    </section>
  );
};
