import React from "react";
import Layout from "../components/Layout";
import LandingPageHero from "../components/LandingPageHero";
import LandingPageHowTo from "../components/LandingPageHowTo";
import LandingPageProductCategories from "../components/LandingPageProductCategories";
import LandingPageEmailSection from "../components/LandingPageEmailSection";
import LandingPageTestimonials from "../components/LandingPageTestimonials";
import LandingPageGetStartedBlock from "../components/LandingPageGetStartedBlock";
import MissionStatementSection from "../components/MissionStatementSection";

export default () => (
  <Layout showNavbar={false}>
    <LandingPageHero />
    <LandingPageHowTo />
    <LandingPageProductCategories />
    <LandingPageEmailSection />
    <LandingPageTestimonials />
    <LandingPageGetStartedBlock />
    <MissionStatementSection />
  </Layout>
);
