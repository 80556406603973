import React from "react";

export default (props) => {
  return (
    <>
      <p className="pt-1 text-blue-500 lg:text-lg">{props.user}</p>
      <p className="pt-1 mb-6 text-blue-500 lg:text-lg">{props.location}</p>
    </>
  );
};
