import React, { useState } from "react";
import { Input, Button, Notification } from "antd";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";
import tccNotification from "../../utils/tccNotification";
import { isNullEmptyOrUndefined } from "../../utils/StringUtils";

/**
 * The Landing Page Newsletter Form
 *
 * This component contains the logic and form to collect an email address from the user and send it via a post
 * to a GetForm account for storage.
 */
export default () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const [emailAddress, setEmailAddress] = useState("");

  const handleEmailAddressChange = (evt) => {
    setEmailAddress(evt.target.value);
  };

  const displayNotificationUsing = (notificationArguments) => {
    Notification.open(notificationArguments);
  };

  const handleServerResponse = (ok, msg, form) => {
    const notificationArguments = ok
      ? tccNotification.buildSuccessNotificationArgsFrom("Thanks", "Your email address has been received & we will send our latest deals real soon.")
      : tccNotification.buildFailedNotificationArgsFrom(
          "There was an error",
          "Thank you for trying to submit your email address. It seems there was a problem that was likely our fault. Please try again."
        );

    displayNotificationUsing(notificationArguments);

    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
      setEmailAddress("");
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/6d2391b6-912e-4027-aaca-3ffe6a450814",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Done", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <form className="flex flex-wrap items-center mx-auto mt-6 w-10/10 lg:w-8/12 " onSubmit={handleOnSubmit}>
      <div className="w-10/12 mx-auto lg:mx-0 lg:ml-auto lg:w-full">
        <label htmlFor="emailInput" className="text-gray-500" required="required">
          Email address
        </label>
        <Input
          type="email"
          value={emailAddress}
          onChange={handleEmailAddressChange}
          name="email"
          className=""
          id="emailInput"
          aria-describedby="emailHelp"
          size="large"
          placeholder="Correo electrónico"
          prefix={<MailOutlined className="text-gray-100 " />}
        />
      </div>
      <Button
        htmlType="submit"
        className="w-10/12 h-10 py-2 mx-auto my-4 text-gray-500 bg-yellow-500 border border-yellow-500 disabled:text-gray-300 disabled:border-yellow-500 disabled:bg-yellow-500 lg:w-full hover:shadow-xl hover:border-yellow-500 hover:bg-gray-500 hover:text-yellow-500"
        disabled={isNullEmptyOrUndefined(emailAddress) || serverState.submitting}
      >
        Regístrate
      </Button>
    </form>
  );
};
