import React from "react";
import UserLocation from "../UserLocation";

/**
 * Renders a component that consumes an instance of the UserLocation component within a text UI element.
 */
export default () => {
  return (
    <div className="flex flex-wrap overflow-hidden">
      <div className="w-full overflow-hidden text-blue-500 lg:pt-6">
        <span className="inline-block mr-2"> Ofertas destacadas para</span>
        <span className="inline-block">
          <UserLocation />
        </span>
      </div>
    </div>
  );
};
