import React from "react";
import Navbar from "../Navbar";
import LandingPageHeroCopy from "../LandingPageHeroCopy";
import LandingPageHeroPriceTiles from "../LandingPageHeroPriceTiles";
import LandingPageHeroSupplierBanner from "../LandingPageHeroSupplierBanner";

export default () => {
  return (
    <div className="w-full bg-gradient-to-b from-white to-blue-100">
      <div className="container mx-auto">
        <div className="flex flex-wrap pb-64 overflow-hidden">
          <div className="w-full overflow-hidden">
            <header>
              <Navbar />
            </header>
            <LandingPageHeroCopy />
            <LandingPageHeroPriceTiles />
            <LandingPageHeroSupplierBanner />
          </div>
        </div>
      </div>
    </div>
  );
};
