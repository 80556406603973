import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { convertToSentenceCase } from "../../utils/StringUtils";
import WindowSizeContext from "../../contexts/windowSizeContext";
import { convertUnderscoresToSpaces } from "../../utils/StringUtils";

/**
 * Generates an element that renders a div which represents a Product category button, used in the
 * Landing Page, Product Catagories filter UI.
 *
 * @param {string} activeProductCategory A string containing the name of the currently selected category in the parent component.
 * @param {string} productCategory A string containing the product category to display as the label for the button.
 * @param {func} filterProductCategoryHandler The function that is executed on the buttons click event. Will cause the parent container to filter product catagories for the selected button.
 * @param {Array} children The children prop is used to pass the SVG element to be displayed as the buttons image.
 *
 * @returns {element} A div that renders the button based on the provided props.
 */
const LandingPageProductCategoryButton = ({ activeProductCategory, productCategory, filterProductCategoryHandler, children }) => {
  const isSelectedProductCategory = activeProductCategory === productCategory;

  const windowSize = useContext(WindowSizeContext);
  const [isLargeScreenView, setIsLargeScreenView] = useState(windowSize.isLargeScreenView);

  useEffect(() => {
    setIsLargeScreenView(windowSize.isLargeScreenView);
  }, [windowSize.isLargeScreenView]);

  return (
    <>
      {isLargeScreenView ? (
        <button
          onClick={() => filterProductCategoryHandler(productCategory)}
          className={`flex-1 p-4 mr-1 text-center bg-blue-100 border ${
            isSelectedProductCategory ? `border-yellow-500 ` : `border-blue-100 `
          }  rounded-md hover:cursor-pointer hover:underline hover:border-yellow-500 first:ml-0 last:mr-0 `}
        >
          <div className="py-2">{children}</div>
          <span className="py-2 mx-auto text-gray-500 hover:font-bold">{convertToSentenceCase(convertUnderscoresToSpaces(productCategory))}</span>
        </button>
      ) : (
        <div>
          <button
            onClick={() => filterProductCategoryHandler(productCategory)}
            className={`w-20 h-24 mr-1 text-center bg-blue-100 lg:flex lg:flex-1 border ${
              isSelectedProductCategory ? `border-yellow-500 ` : `border-blue-100 `
            }  rounded-md hover:cursor-pointer hover:underline hover:border-yellow-500 first:ml-0 last:mr-0 `}
          >
            <div className="py-2">{children}</div>
            <span className="py-2 mx-auto text-gray-500 focus:font-bold">{convertToSentenceCase(convertUnderscoresToSpaces(productCategory))}</span>
          </button>
        </div>
      )}
    </>
  );
};

LandingPageProductCategoryButton.propTypes = {
  activeProductCategory: PropTypes.string.isRequired,
  productCategory: PropTypes.string.isRequired,
  filterProductCategoryHandler: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default LandingPageProductCategoryButton;
