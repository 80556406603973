import React from "react";
import PropTypes from "prop-types";

/**
 * Landing Page Email Section Mobile View Component.
 *
 * This component renders the mobile view of the landing pages email form. This view stacks the two containers vertically
 * to make use of the smaller screen.
 *
 * @param {element} form The form to be used to collect the users email details.
 * @param {element} formIcon The icon to be show at the bottom of the email form.
 */
const LandingPageVerticalEmailSection = ({ form, formIcon }) => {
  return (
    <div className="flex flex-col mx-4 rounded-md ">
      <div className="w-full pt-2 pb-2 pl-4 rounded-md pb-18 bg-gradient-to-b from-white to-blue-100">
        <div className="inline-block w-1/2 py-4 align-top ">
          <h2 className="p-0">Ahorra dinero.</h2>
          <h2 className="p-0">Ahorra tiempo.</h2>
          <h2 className="p-0">Protege tu privacidad.</h2>
        </div>
        <div className="inline-block w-1/2 ">
          <h3 className="w-full pt-4 text-lg text-blue-500">Deja de preocuparte por las facturas.</h3>
        </div>
      </div>
      <div className="w-full pt-2 bg-gray-500 rounded-md ">
        <div className="w-10/12 mx-auto">
          <h3 className="text-xl text-yellow-500 ">Nos encargamos de comparar los precios por ti.</h3>
          <h3 className="text-xl text-white">Regístrate y recibe los mejores precios y ofertas por correo electrónico.</h3>
        </div>
        {form}
        <div className="block float-right -mr-16 bg-transparent">{formIcon}</div>
      </div>
    </div>
  );
};

LandingPageVerticalEmailSection.propTypes = {
  form: PropTypes.element.isRequired,
  formIcon: PropTypes.element.isRequired,
};

export default LandingPageVerticalEmailSection;
