import React from "react";
import PropTypes from "prop-types";
/**
 * Landing Page Email Section Large View Component.
 *
 * This component renders the desktop view of the landing pages email form. This view stacks the two containers vertically
 * to make use of the smaller screen.
 *
 * @param {element} form The form to be used to collect the users email details.
 * @param {element} formIcon The icon to be show at the bottom of the email form.
 */

const LandingPageHorizontalEmailSection = ({ form, formIcon }) => {
  return (
    <div className="flex flex-row mx-16 rounded-md bg-gradient-to-b from-white to-blue-100">
      <div className="w-6/12 pt-20 pl-24 rounded-md pb-18">
        <h2 className="p-0">Ahorra dinero.</h2>
        <h2 className="p-0">Ahorra tiempo.</h2>
        <h2 className="p-0">Protege tu privacidad.</h2>
        <h3 className="w-6/12 pt-12 text-xl text-blue-500">Deja de preocuparte por las facturas.</h3>
      </div>
      <div className="w-6/12 pt-20 bg-gray-500 rounded-md ">
        <div className="w-10/12 mx-auto">
          <h3 className="text-xl text-yellow-500 ">Nos encargamos de comparar los precios por ti.</h3>
          <h3 className="text-xl text-white">Regístrate y recibe los mejores precios y ofertas por correo electrónico.</h3>
        </div>
        {form}
        <div className="block float-right -mr-24 bg-transparent">{formIcon}</div>
      </div>
    </div>
  );
};

LandingPageHorizontalEmailSection.propTypes = {
  form: PropTypes.element.isRequired,
  formIcon: PropTypes.element.isRequired,
};

export default LandingPageHorizontalEmailSection;
