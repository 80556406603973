import React, { useContext, useEffect, useState } from "react";
import LandingPageHeroPriceTile from "../LandingPageHeroPriceTile";
import TCCPiggyWithCoinSVG from "../../../svg/LandingPageHero/tccpiggycoin.svg";
import LandingPageHeroUserLocation from "../LandingPageHeroUserLocation";
import LandingPageHeroPriceTileHoverImage from "../LandingPageHeroPriceTileHoverImage";
import WindowSizeContext from "../../contexts/windowSizeContext";

import CarInsuranceSVG from "../../../svg/LandingPageHeroPriceTile/carinsurance.svg";
import CarInsuranceHoverSVG from "../../../svg/LandingPageHeroPriceTile/carinsurance-hover.svg";
import CarLoanSVG from "../../../svg/LandingPageHeroPriceTile/carloans.svg";
import CarLoanHoverSVG from "../../../svg/LandingPageHeroPriceTile/carloans-hover.svg";
import CellHandsetSVG from "../../../svg/LandingPageHeroPriceTile/cell.svg";
import CellHandsetHoverSVG from "../../../svg/LandingPageHeroPriceTile/cell-hover.svg";
import TVPackageSVG from "../../../svg/LandingPageHeroPriceTile/tv.svg";
import TVPackageHoverSVG from "../../../svg/LandingPageHeroPriceTile/tv-hover.svg";
import ElectricRateSVG from "../../../svg/LandingPageHeroPriceTile/electricity.svg";
import ElectricRateHoverSVG from "../../../svg/LandingPageHeroPriceTile/electricity-hover.svg";
import InternetPlansSVG from "../../../svg/LandingPageHeroPriceTile/broadband.svg";
import InternetPlansHoverSVG from "../../../svg/LandingPageHeroPriceTile/broadband-hover.svg";

/**
 * This component renders the container for the price tiles that are rendered within the Landing Page Hero.
 *
 * @returns {element} A section element containing the layout and he child price tile's.
 */
export default () => {
  const windowSize = useContext(WindowSizeContext);

  const [isLargeScreenView, setIsLargeScreenView] = useState(windowSize.isLargeScreenView);

  useEffect(() => {
    setIsLargeScreenView(windowSize.isLargeScreenView);
  }, [windowSize.isLargeScreenView]);

  return (
    <section>
      <div className="flex flex-wrap pt-6 overflow-hidden">
        <div className="w-full h-0 overflow-hidden xl:w-3/12"></div>

        <div className="w-full overflow-hidden text-center -pt-6 xl:w-5/12">
          <div className="flex flex-wrap overflow-hidden ">
            <div className="w-1/2 overflow-hidden lg:w-1/3 xl:w-1/3">
              <LandingPageHeroPriceTile
                productCategory="Tarifas eléctricas"
                subText="Anual (500kwh mensual)"
                nonTccPrice="$780"
                tccPrice="$690"
                linkTitle="Tarifas eléctricas"
                resultPageUrl="/utilidades/comparando-tarifas-eléctricas/"
              >
                <LandingPageHeroPriceTileHoverImage initialImage={<ElectricRateSVG />} hoverImage={<ElectricRateHoverSVG />} />
              </LandingPageHeroPriceTile>
            </div>

            <div className="w-1/2 overflow-hidden lg:w-1/3 xl:w-1/3">
              <LandingPageHeroPriceTile
                productCategory="Seguro de auto"
                linkTitle="Seguro de auto"
                subText="Representante"
                nonTccPrice="$204"
                tccPrice="$179"
                resultPageUrl="/seguro/compara-proveedores-de-seguros-de-auto/"
              >
                <LandingPageHeroPriceTileHoverImage initialImage={<CarInsuranceSVG />} hoverImage={<CarInsuranceHoverSVG />} />
              </LandingPageHeroPriceTile>
            </div>

            <div className="w-1/2 overflow-hidden lg:w-1/3 xl:w-1/3">
              <LandingPageHeroPriceTile
                productCategory="Teléfonos móviles"
                linkTitle="Teléfonos móviles"
                subText="Contrato de 24 meses"
                nonTccPrice="$1299"
                tccPrice="$1099"
                resultPageUrl="/celular/compara-teléfonos-móviles/"
              >
                <LandingPageHeroPriceTileHoverImage initialImage={<CellHandsetSVG />} hoverImage={<CellHandsetHoverSVG />} />
              </LandingPageHeroPriceTile>
            </div>

            <div className="w-1/2 overflow-hidden lg:pt-6 lg:w-1/3 xl:w-1/3">
              <LandingPageHeroPriceTile
                productCategory="Paquetes de TV"
                linkTitle="Paquetes de TV"
                subText="Mínimo 12 meses"
                nonTccPrice="$59.99"
                tccPrice="$49.99"
                resultPageUrl="hogar/compara-paquetes-de-tv/"
              >
                <LandingPageHeroPriceTileHoverImage initialImage={<TVPackageSVG />} hoverImage={<TVPackageHoverSVG />} />
              </LandingPageHeroPriceTile>
            </div>

            <div className="w-1/2 overflow-hidden lg:pt-6 lg:w-1/3 xl:w-1/3">
              <LandingPageHeroPriceTile
                productCategory="Planes de Internet"
                linkTitle="Planes de Internet"
                subText="Mínimo 12 meses"
                nonTccPrice="$49.99"
                tccPrice="$29.99"
                resultPageUrl="/hogar/compara-proveedores-de-servicio-de-Internet-doméstico/"
              >
                <LandingPageHeroPriceTileHoverImage initialImage={<InternetPlansSVG />} hoverImage={<InternetPlansHoverSVG />} />
              </LandingPageHeroPriceTile>
            </div>

            <div className="w-1/2 overflow-hidden lg:pt-6 lg:w-1/3 xl:w-1/3">
              <LandingPageHeroPriceTile
                productCategory="Préstamos para automóviles"
                linkTitle="Préstamos para automóviles"
                subText="Representante"
                nonTccPrice="$27"
                tccPrice="$13"
                resultPageUrl="/préstamos/comparar-productos-de-préstamo-para-auto/"
              >
                <LandingPageHeroPriceTileHoverImage initialImage={<CarLoanSVG />} hoverImage={<CarLoanHoverSVG />} />
              </LandingPageHeroPriceTile>
            </div>
          </div>
          <div className="w-full pt-8 pb-10 overflow-hidden lg:pt-10">
            <LandingPageHeroUserLocation />
          </div>
        </div>

        {isLargeScreenView && (
          <div className="w-full pt-8 overflow-hidden align-top xl:w-4/12">
            <TCCPiggyWithCoinSVG className="mt-10 ml-auto" />
          </div>
        )}
      </div>
    </section>
  );
};
