export default {
  landingPageTestimonials: [
    {
      testimonial: "Pude encontrar fácilmente un nuevo precio para mi suministro eléctrico, y pude arreglarlo sin tener que tratar con un montón de proveedores.",
      userName: "Clive Garcia",
      location: "Austin, Tx",
      rating: "4",
    },
    {
      testimonial:
        "He usado esta app para averiguar cómo podía refinanciar mi préstamo estudiantil. Fue muy útil disponer de una comparación fácil de ver de las compañías de préstamos. Me ahorré tener que estar mirando varias páginas web abiertas a la vez.",
      userName: "Karen Allen",
      location: "Addison, TX",
      rating: "4",
    },
    {
      testimonial: "Espero que The Comparison Company siga agregando más servicios y proveedores porque creo que sería muy útil. Por supuesto la agregaré a favoritos.",
      userName: "Frank Williams",
      location: "Dallas, Tx",
      rating: "3",
    },
    {
      testimonial: "Encontré todos los servicios que necesitaba para empezar mi propio negocio cuando estaba desempleada. Es un recurso realmente útil.",
      userName: "Lorraine Rodriguez",
      location: "Dallas, TX",
      rating: "4",
    },
  ],
  userProfileTestimonials: [
    {
      testimonial: "I was able to easily find a new price for my electricity supply and was able to arrange it with out dealing with lots of suppliers.",
      userName: "Clive Garcia",
      location: "Austin, Tx",
      rating: "4",
    },
    {
      testimonial:
        "I have used this app to find out how to refinance my student loan. I found it useful to have an easy to view comparison of the loan companies. It saved me switching between tabs in my browser.",
      userName: "Karen Allen",
      location: "Addison, TX",
      rating: "4",
    },
    {
      testimonial: "I hope that The Comparison Company continues to add more services and providers as I think it could be very useful. Will definitely bookmark.",
      userName: "Frank Williams",
      location: "Dallas, Tx",
      rating: "3",
    },
    {
      testimonial: "I found all the services I needed to start my own business when I was without a job. This is a seriously useful resource.",
      userName: "Lorraine Rodriguez",
      location: "Dallas, TX",
      rating: "4",
    },
  ],
};
