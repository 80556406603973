import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";

const LandingPageProductCategoryLink = ({ productSubCategory, productSubCategoryUrl }) => {
  return (
    <Link className="p-2 my-2 mr-2 text-gray-500 whitespace-no-wrap border border-gray-200 hover:cursor-pointer hover:border-yellow-500 hover:text-gray-500 hover:underline" to={productSubCategoryUrl}>
      <span key={uuidv4()}>{productSubCategory}</span>
    </Link>
  );
};

LandingPageProductCategoryLink.propTypes = {
  productSubCategory: PropTypes.string.isRequired,
  productSubCategoryUrl: PropTypes.string.isRequired,
};

LandingPageProductCategoryLink.defaultProps = {
  productSubCategoryUrl: "/premium",
};

export default LandingPageProductCategoryLink;
