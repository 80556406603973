import React from "react";

import AllState from "../../../svg/LandingPageHeroSupplierBanner/allstatewhite.svg";
import Aspiration from "../../../svg/LandingPageHeroSupplierBanner/Aspiration.svg";
import ATnT from "../../../svg/LandingPageHeroSupplierBanner/ATnT.svg";
import Bestow from "../../../svg/LandingPageHeroSupplierBanner/Bestow.svg";
import BoroCash from "../../../svg/LandingPageHeroSupplierBanner/BoroCash.svg";
import CreditKarma from "../../../svg/LandingPageHeroSupplierBanner/creditkarmawhite.svg";
import DunBradsteet from "../../../svg/LandingPageHeroSupplierBanner/dunbradstreetwhite.svg";
import Xfinity from "../../../svg/LandingPageHeroSupplierBanner/xfinitywhite.svg";
import Earnest from "../../../svg/LandingPageHeroSupplierBanner/earnest.svg";
import Prosper from "../../../svg/LandingPageHeroSupplierBanner/prosperwhite.svg";
import LoanDepot from "../../../svg/LandingPageHeroSupplierBanner/loandepotwhite.svg";

export default () => {
  return (
    <section>
      <div className="flex flex-wrap overflow-hidden bg-gray-500 border border-gray-500">
        <div className="w-full overflow-hidden xl:w-1/12"></div>

        <div className="w-full h-24 pt-3 mx-auto overflow-hidden text-center border border-gray-500 lg:mx-0 xl:w-10/12 lg:h-12">
          <AllState className="inline-block w-20 h-6 mr-2" />
          <Aspiration className="inline-block w-20 h-6 mr-2" />
          <BoroCash className="inline-block w-20 h-6 mr-2" />
          <Prosper className="inline-block w-20 h-6 mr-2" />
          <CreditKarma className="inline-block w-20 h-6 mr-2" />
          <DunBradsteet className="inline-block w-20 h-6 mr-2" />
          <Xfinity className="inline-block w-20 h-6 mr-2" />
          <ATnT className="inline-block w-20 h-6 mr-2" />
          <Earnest className="inline-block w-20 h-4 mr-2" />
          <Bestow className="inline-block w-20 h-6 mr-2" />
          <LoanDepot className="inline-block w-20 h-6 mr-2" />
        </div>

        <div className="w-full overflow-hidden xl:w-1/12"></div>
      </div>
    </section>
  );
};
