import React, { useEffect, useContext } from "react";
import LandingPageNewsLetterForm from "../LandingPageNewsLetterForm";
import PiggySavingsSVG from "../../../svg/LandingPageEmailSection/piggysavings.svg";
import LandingPageHorizontalEmailSection from "../LandingPageHorizontalEmailSection";
import LandingPageVerticalEmailSection from "../LandingPageVerticalEmailSection";
import WindowSizeContext from "../../contexts/windowSizeContext";

/**
 * The Landing Page Email Section
 *
 * This is the container section that will render one of two views based on the screen size. It is responsible for
 * providing the correct Landing Page Section component, configured with the form and icon required to capture the
 * users email details.
 */
export default () => {
  const windowSize = useContext(WindowSizeContext);

  const [isLargeScreenView, setIsLargeScreenView] = React.useState(windowSize.windowSize);

  useEffect(() => {
    setIsLargeScreenView(windowSize.isLargeScreenView);
  }, [windowSize.isLargeScreenView]);

  return (
    <section>
      <div className="container mx-auto">
        <div className="flex flex-wrap -mt-12 overflow-hidden">
          {isLargeScreenView ? (
            <>
              <div className="w-full overflow-hidden xl:w-1/12"></div>
              <div className="w-full overflow-hidden xl:w-10/12">
                <LandingPageHorizontalEmailSection form={<LandingPageNewsLetterForm />} formIcon={<PiggySavingsSVG className="block w-56 h-24 pl-12" />} />
              </div>
              <div className="w-full overflow-hidden xl:w-1/12"></div>
            </>
          ) : (
            <div className="w-full">
              <LandingPageVerticalEmailSection form={<LandingPageNewsLetterForm />} formIcon={<PiggySavingsSVG className="block w-56 h-24 pl-8" />} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
