import React from "react";

export default () => {
  return (
    <section>
      <div className="flex flex-wrap pt-4 overflow-hidden lg:pt-10">
        <div className="w-full overflow-hidden xl:w-2/12"></div>

        <div className="w-full overflow-hidden text-center xl:w-7/12">
          <h1 className="text-2xl">El único sitio donde ahorrar dinero en todas tus facturas a la vez</h1>
          <h2 className="text-lg text-blue-500 lg:mt-2">Nuestra Inteligencia Artificial compara precios de todo Internet para ahorrarte el trabajo</h2>
          <h3 className="text-sm text-gray-500 lg:mt-6">Obtén ya gratis los 5 mejores resultados </h3>
        </div>

        <div className="w-full overflow-hidden xl:w-3/12"></div>
      </div>
    </section>
  );
};
