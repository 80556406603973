// eslint-disable-next-line no-unused-vars
import { window } from "browser-monads";

const isValid = (obj) => {
  return obj && obj.length > 0;
};

export const getInitialSearchParamsUsing = (window) => {
  const defaultSearchParams = "celular";

  if (window && window.history.state) {
    return window.history.state.searchParams ?? defaultSearchParams;
  }

  return defaultSearchParams;
};

export const getDefaultSearchParamsFrom = (productCategoryData) => {
  if (!isValid(productCategoryData)) {
    throw new Error("Null or empty product category data array, unable to filter articles");
  }
  return productCategoryData[0];
};

export const getProductSubCategoryDataUsing = (productCategory, productCategoryData) => {
  return productCategoryData.filter((item) => item.parentMenu.toLowerCase() === productCategory.toLowerCase());
};

export const getProductConfigurationDataItemUsing = (productCategory, productCategoryConfigurations) => {
  if (!isValid(productCategoryConfigurations)) {
    throw new Error("Null or empty product category configuration data array, unable to filter articles");
  }

  if (productCategory) {
    return productCategoryConfigurations.find((config) => config.category.toLowerCase() === productCategory.toLowerCase());
  }

  throw new Error("Null or empty product category data array, unable to filter articles");
};
