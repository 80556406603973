import React from "react";
import FilledStarSvg from "../../../svg/starfill.svg";
import EmptyStarSvg from "../../../svg/starempty.svg";
import * as ratingUtils from "../../utils/ratingUtils";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  return (
    <div className="inline-flex">
      {ratingUtils.createRatingArrayFrom(props.rating).map((r) => {
        if (r === 1) {
          return <FilledStarSvg key={uuidv4()} size={1} />;
        } else {
          return <EmptyStarSvg key={uuidv4()} />;
        }
      })}
    </div>
  );
};
