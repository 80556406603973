import React from "react";
import LandingPageHowToBlock from "../LandingPageHowToBlock";

import HowToSaveTimeGif from "../../gifs/LandingPageHowTo/howtosavetime.gif";
import HowToSearchGif from "../../gifs/LandingPageHowTo/howtosearch.gif";
import HowToMaintainPrivacyGif from "../../gifs/LandingPageHowTo/howtomaintainprivacy.gif";
import HowToRelaxGif from "../../gifs/LandingPageHowTo/howtorelax.gif";

export default () => {
  return (
    <section className="-mt-64">
      <div className="container pt-6 mx-auto">
        <h1 className="mt-10 text-3xl text-center">Cómo comparar en Comparación</h1>
        <div className="flex flex-wrap mt-10 overflow-hidden">
          <div className="w-full overflow-hidden xl:w-1/12"></div>

          <div className="w-full overflow-hidden text-center xl:w-10/12">
            <div className="flex flex-wrap pb-0 overflow-hidden">
              <div className="w-full overflow-hidden xl:w-1/4">
                <LandingPageHowToBlock
                  blockGif={HowToSaveTimeGif}
                  blockTitle="Nuestro software busca constantemente los mejores precios"
                  blockText="Por primera vez sabrás seguro que obtuviste la mejor oferta"
                />
              </div>

              <div className="w-full overflow-hidden xl:w-1/4">
                <LandingPageHowToBlock
                  blockGif={HowToSearchGif}
                  blockTitle="Todos los precios que necesitas en un solo lugar, permanentemente"
                  blockText="Elige un servicio para el que quieras ver comparaciones o combínalos para ahorrar aún más "
                />
              </div>

              <div className="w-full overflow-hidden xl:w-1/4">
                <LandingPageHowToBlock
                  blockGif={HowToMaintainPrivacyGif}
                  blockTitle="Protege tu privacidad y resguarda tus datos de los proveedores"
                  blockText="Tenemos una forma única de asegurarnos de que tus datos estén protegidos. Regístrate y no volverás a recibir jamás una llamada automática o correo no deseado "
                />
              </div>

              <div className="w-full overflow-hidden xl:w-1/4">
                <LandingPageHowToBlock
                  blockGif={HowToRelaxGif}
                  blockTitle="Configura tu perfil una vez e inscríbete fácilmente en los servicios"
                  blockText="Guarda tu información en un perfil seguro y úsalo para todas las comparaciones de precios"
                />
              </div>
            </div>
          </div>

          <div className="w-full overflow-hidden xl:w-1/12"></div>
        </div>
      </div>
    </section>
  );
};
