import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const LandingPageHeroPriceTile = ({ productCategory, subText, nonTccPrice, tccPrice, resultPageUrl, linkTitle, children }) => {
  return (
    <div className="p-1">
      <div className="flex justify-center w-full mx-auto overflow-hidden align-middle">
        <Link className="block" to={resultPageUrl} aria-label={linkTitle ?? productCategory}>
          {children}
        </Link>
      </div>
      <div className="w-full pt-4 overflow-hidden">
        <h4 className="py-0 text-center text-gray-500 ">{productCategory}</h4>
      </div>
      <div className="w-full pb-3 overflow-hidden lg:pb-0">
        <p className="p-0 text-xs text-center text-blue-500">{subText}</p>
      </div>
    </div>
  );
};

LandingPageHeroPriceTile.propTypes = {
  productCategory: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  nonTccPrice: PropTypes.string.isRequired,
  tccPrice: PropTypes.string.isRequired,
  resultPageUrl: PropTypes.string,
  children: PropTypes.element.isRequired,
  linkTitle: PropTypes.string.isRequired,
};

LandingPageHeroPriceTile.defaultProps = {
  resultPageUrl: "/premium",
};

export default LandingPageHeroPriceTile;
