import React from "react";
import PropTypes from "prop-types";

const LandingPageHowToBlock = ({ blockGif, blockTitle, blockText }) => {
  return (
    <div className="w-full overflow-hidden">
      <img src={blockGif} alt="How to save time &amp; money on The Comparison Company while protecting your privacy." className="w-40 mx-auto" />
      <div className="w-full pt-4 pb-0 lg:py-10">
        <div className="inline-flex flex-wrap items-start justify-center pb-6 align-bottom lg:pb-0">
          <h2 className="w-full text-xl text-center text-blue-500 lg:h-20">{blockTitle}</h2>
          <p className="text-center text-gray-500 lg:pt-4">{blockText}</p>
        </div>
      </div>
    </div>
  );
};

LandingPageHowToBlock.propTypes = {
  blockGif: PropTypes.string.isRequired,
  blockTitle: PropTypes.string.isRequired,
  blockText: PropTypes.string.isRequired,
};

export default LandingPageHowToBlock;
