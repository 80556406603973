import React from "react";
import CardCarousel from "../CardCarousel";
import QuoteSvg from "../../../svg/quote.svg";
import LandingPageTestimonialCard from "../TestimonialCard";
import TestimonialCardText from "../TestimonialCardText";
import TestimonialCardUserDetails from "../TestimonialCardUserDetails";
import TestimonialCardRating from "../TestimonialCardRating";
import testimonialData from "../../data/testimonialData";
import { v4 as uuidv4 } from "uuid";

export default () => {
  return (
    <section>
      <div className="container mx-auto">
        <div className="flex flex-wrap mt-10 overflow-hidden">
          <div className="w-full overflow-hidden xl:w-2/12"></div>

          <div className="w-full px-2 overflow-hidden text-center xl:w-8/12">
            <h1 className="text-2xl">Por esto hacemos lo que hacemos</h1>
            <h2 className="text-lg text-blue-500 lg:mt-2">Lanzamos The Comparison Company para ahorrarte tiempo y dinero.</h2>
            <h2 className="text-lg text-blue-500 lg:mt-2">No es que lo digamos nosotros, esto es lo que dicen nuestros clientes de nosotros:</h2>
          </div>

          <div className="w-full overflow-hidden xl:w-2/12"></div>
        </div>
      </div>

      <div className="container mx-auto">
        <div className="flex flex-wrap mt-10 overflow-hidden">
          <div className="w-full overflow-hidden xl:w-1/12"></div>

          <div className="w-full pb-10 overflow-hidden text-center bg-blue-500 lg:rounded-md xl:w-10/12">
            <QuoteSvg className="h-16 mb-16 ml-32 lg:mb-30" />
            <div className="relative w-full h-64 mx-auto my-10 ">
              <CardCarousel>
                {testimonialData.landingPageTestimonials.map((t) => (
                  <LandingPageTestimonialCard key={uuidv4()}>
                    <TestimonialCardText>{t.testimonial}</TestimonialCardText>
                    <TestimonialCardUserDetails user={t.userName} location={t.location} />
                    <TestimonialCardRating rating={t.rating} />
                  </LandingPageTestimonialCard>
                ))}
              </CardCarousel>
            </div>
          </div>

          <div className="w-full overflow-hidden xl:w-1/12"></div>
        </div>
      </div>
    </section>
  );
};
